:root {
  --mainColor: #F47525;
  --textColor: #fff;
}

.menuContent .categoryTitle {
  line-height: 2;
  background-color: var(--mainColor);
  text-transform: uppercase;
}



@media print {
  :root {
    --textColor: #000;
  }
  body {
    /* background: url('../public/images/background_print.png') !important; */
    background: none transparent !important;
    color: var(--textColor) !important;
    font-size: 13px;
  }
  body .menuContent * {
    color: var(--textColor) !important;
  }
  .menuContent {
    padding-top: 90px;
  }
  .menuContent .menuCategory {
    column-gap: 40px;
  }
  .menuContent .menuCategory .pageSpacer {
    clear: both;
    page-break-before: always;
    padding-top: 100px;
  }
  .menuContent .categoryTitle {
    margin-top: 0 !important;
    background-color: transparent !important;
    border-bottom: 1px solid;
    border-radius: 0;
    text-transform: none;
    line-height: 2;
    font-style: italic;
  }

  .menuCategory .menuItem {
    background-color: transparent !important;
  }
  .menuCategory:not(.menuBeer) .menuItem {
    flex: 0 0 calc(50% - 20px) !important;
    margin-bottom: 8px !important;
  }
  .menuCategory.menuBeer .menuItem {
    padding-bottom: 16px !important;
    border-bottom: 1px solid;
    border-radius: 0;
  }
  .menuCategory .menuItem .MuiCardContent-root {
    padding: 0 !important;
  }

  .menuItem .beerPriceTable {
    background-color: transparent !important;
  }

  .pagebreak {
    clear: both;
    page-break-after: always;
  }
  .menuFab {
    display: none !important;
  } 
  .copyright {
    display: none;
  }
  .tipBere {
    font-size: .7rem;
  }

  .statements {
    /* display: none; */
  }
}